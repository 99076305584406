import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CSS/AdminOrder.css";

const AdminOrder = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch orders when the component mounts
  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${API_BASE_URL}/adminorder`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (Array.isArray(response.data)) {
          setOrders(response.data);
        } else {
          setError("Unexpected response structure.");
        }
      } catch (error) {
        setError("Failed to fetch orders.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [API_BASE_URL]);

  // Handle status change
  const handleStatusChange = async (orderId, newStatus) => {
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${API_BASE_URL}/adminorder/${orderId}/status`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Update local state with the new status
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
      alert("Order status updated successfully!");
    } catch (error) {
      setError("Failed to update order status.");
      console.error(error);
    }
  };

  if (loading) return <p>Loading orders...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="admin-order-container">
      <h1>Admin Orders</h1>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Date</th>
            <th>Total (EGP)</th>
            <th>Items</th>
            <th>Update Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.length === 0 ? (
            <tr>
              <td colSpan="5">No orders available</td>
            </tr>
          ) : (
            orders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{new Date(order.created_at).toLocaleDateString()}</td>
                <td>{order.total}</td>
                <td>
                  <ul>
                    {order.items && order.items.length > 0 ? (
                      order.items.map((item) => (
                        <li key={item.id}>
                          <p>Product: {item.product.name}</p>
                          <p>Quantity: {item.quantity}</p>
                          <p>Price: {item.price} EGP</p>
                        </li>
                      ))
                    ) : (
                      <li>No items available</li>
                    )}
                  </ul>
                </td>
                <td>
                  <select
                    value={order.status}
                    onChange={(e) =>
                      handleStatusChange(order.id, e.target.value)
                    }
                  >
                    <option value="Pending">Pending</option>
                    <option value="Processing">Processing</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminOrder;
