import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import ProductCard from './HomeCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CSS/admin.css';

export default function AdminHome() {
  const [products, setProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchData();
  }, []);

  // Fetch products from the API
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${API_BASE_URL}/products`);
      setProducts(data.slice(0, 10)); // Trending products
      setNewProducts(data.slice(20, 30)); // New arrivals
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Slider settings
  const sliderSettings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="container">
      <div className="row">
        {/* Trending Products */}
        <h1 className="text-center trending">Trending Products</h1>
        <Slider {...sliderSettings}>
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </Slider>

        {/* New Arrivals */}
        <h1 className="text-center trending">New Arrivals</h1>
        <Slider {...sliderSettings}>
          {newProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
